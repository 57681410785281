@import url("https://fonts.googleapis.com/css?family=Space+Mono&display=swap");

:root {
  --base-500: #a0aec0;
  --base-200: #e2e8f0;
  --base-300: #e2e8f0;
  --base-400: #cbd5e0;
  --main-500: #6366f1;
  --main-400: #7f9cf5;
  --main-200: #c3dafe;
  --main-100: #ebf4ff;
  --solid: #fff;
  --solid-900: #4a5568;
  --sidebar-bg: #edf2f7;
  --sidebar-color: #4a5568;
  --shadow: 0 10px 15px -3px rgba(36, 69, 101, 0.19),
    0 4px 6px -2px rgb(208, 220, 232);
  --shadow-active: 0 0 0 2px currentColor,
    0 10px 15px -3px rgba(36, 69, 101, 0.19), 0 4px 6px -2px rgb(208, 220, 232);
  --opener: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='white' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' viewBox='0 0 24 24'%3E%3Cpath d='M3 12h18M3 6h18M3 18h18'/%3E%3C/svg%3E");
  --opener-active: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='white' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Cpath d='M19 12H5M12 19l-7-7 7-7'/%3E%3C/svg%3E");
}

.dark-mode {
  --base-500: #718096;
  --base-200: #2d3748;
  --base-300: #4a5568;
  --base-400: #4a5568;
  --main-500: #6366f1;
  --main-200: #4a5568;
  --main-100: #2d3648;
  --solid: #111827;
  --solid-900: #d1dcec;
  --sidebar-bg: #2e3647;
  --sidebar-color: #fff;
  --shadow: 0 10px 15px -3px rgb(26, 32, 45), 0 4px 6px -2px rgb(48, 58, 84);
  --shadow-active: 0 0 0 2px currentColor, 0 10px 15px -3px rgba(0, 0, 0, 0.35),
    0 4px 6px -2px rgb(76, 82, 90);
}

.red:not(.dark-mode) {
  --main-200: #fed7d7;
  --main-100: #fed7d7;
}

.red {
  --main-500: #f56565;
  --main-400: #fc8181;
}

.orange {
  --main-500: #ed8936;
  --main-400: #f6ad55;
}

.orange:not(.dark-mode) {
  --main-200: #feebc8;
  --main-100: #fffaf0;
}

.green {
  --main-500: #48bb78;
  --main-400: #68d391;
}

.green:not(.dark-mode) {
  --main-200: #c6f6d5;
  --main-100: #c6f6d5;
}

.yellow {
  --main-500: #f59e0b;
  --main-400: #fbbf24;
}

.blue {
  --main-500: #4299e1;
  --main-400: #63b3ed;
}

.blue:not(.dark-mode) {
  --main-200: #bee3f8;
  --main-100: #ebf8ff;
}

.purple {
  --main-500: #9f7aea;
  --main-400: #b794f4;
}

.purple:not(.dark-mode) {
  --main-200: #e9d8fd;
  --main-100: #faf5ff;
}

.pink {
  --main-500: #ed64a6;
  --main-400: #f687b3;
}

.pink:not(.dark-mode) {
  --main-200: #fed7e2;
  --main-100: #fff5f7;
}

button {
  user-select: none;
}

button,
button:focus {
  outline: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
  background-color: var(--solid);
}

.app.dark-mode {
  background-color: #111827;
}

.main {
  height: calc(100vh - 64px);
  overflow: auto;
  transition: 0.3s;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track,
::-webkit-scrollbar {
  display: none;
}

.app.has-sidebar .main {
  padding-left: 160px;
}

.sidebar {
  height: 100vh;
  width: 160px;
  z-index: 1;
  position: fixed;
  overflow: auto;
  left: 0;
  transform: translateX(-100%);
  padding: 20px;
  top: 0;
  background-color: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s;
}

.app.has-sidebar .sidebar {
  box-shadow: 2px 68px 10px rgba(194, 206, 219, 0.68);
}
.dark-mode.has-sidebar .sidebar {
  box-shadow: 2px 68px 10px rgba(26, 32, 44, 0.8);
}

.block-category {
  margin-bottom: 10px;
  color: var(--sidebar-color);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
}

.block-item {
  padding: 0;
  border: 0;
  border-radius: 4px;
  box-shadow: var(--shadow);
  overflow: hidden;
  color: var(--main-500);
}

.block-item.is-active {
  background-color: var(--main-500);
  box-shadow: var(--shadow-active);
}

.block-item.is-active > svg {
  opacity: 0.8;
}

.block-item + .block-item {
  margin-top: 20px;
}
.blocks + .blocks {
  margin-top: 30px;
}
svg {
  width: 100%;
}

.app.has-sidebar .toolbar {
  padding: 0 16px 0 176px;
}

.toolbar {
  background-color: var(--main-500);
  transition: padding 0.3s;
  padding: 0 16px;
  height: 64px;
  display: flex;
  align-items: center;
}

.app.has-sidebar .sidebar {
  transform: translateX(0);
}

.switcher {
  display: flex;
  padding: 0 10px;
  height: 32px;
  border-radius: 20px;
  background-color: #fff;
  align-items: center;
  margin-right: 16px;
}

.theme-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.theme-button.is-active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='white' stroke-width='3' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
  background-size: 10px;
  content: "";
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
}

.theme-button + .theme-button {
  margin-left: 5px;
}

.opener {
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 600;
  flex-shrink: 0;
  position: relative;
}

.opener::before {
  background-image: var(--opener);
  display: inline-block;
  width: 18px;
  flex-shrink: 0;
  height: 18px;
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 4px;
}

.app.has-sidebar .opener::before {
  background-image: var(--opener-active);
}

.mode {
  background-color: rgba(255, 255, 255, 0.192);
  border-radius: 30px;
  width: 58px;
  position: relative;
  height: 32px;
  flex-shrink: 0;
  margin-left: 16px;
}

.mode::before {
  width: 32px;
  height: 100%;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='white' stroke-width='2.4' fill='white' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='5'/%3E%3Cpath d='M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42'/%3E%3C/svg%3E");
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  background-size: 50%;
  transition: 0.3s;
  background-position: center;
}

.mode::after {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 6px;
  top: 6px;
  background-color: var(--main-500);
  content: "";
  border-radius: 50%;
  transition: transform 0.3s;
}

.app.dark-mode .mode {
  background-color: rgba(0, 0, 0, 0.171);
}
.app.dark-mode .mode::after {
  transform: translateX(-24px);
}
.app.dark-mode .mode::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' stroke='white' stroke-width='2' fill='white' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1' viewBox='0 0 24 24'%3E%3Cpath d='M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z'/%3E%3C/svg%3E");
  transform: translateX(26px);
}

.device {
  color: #fff;
  opacity: 0.3;
  transition: 0.3s;
}

.device.is-active {
  opacity: 1;
}

.device + .device {
  margin-left: 8px;
}

.view {
  position: relative;
}

.app.desktop .view {
  height: 100%;
}

.device > svg {
  width: 24px;
}

.app.phone .view iframe {
  height: 736px;
  width: 414px;
  margin: 60px auto 0;
  border: 2px solid var(--base-300);
}

.app.phone .view {
  width: 450px;
  height: 880px;
  background-color: var(--sidebar-bg);
  border-radius: 30px;
  border: 2px solid var(--base-300);
  margin: 60px auto;
}

.app.phone:not(.dark-mode) .view::before,
.app.phone:not(.dark-mode) .view::after {
  opacity: 0.6;
}

.app.phone .view::before {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  border: 2px solid var(--base-400);
  content: "";
  width: 40px;
  border-radius: 50%;
  height: 40px;
}

.app.phone .view::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 23px;
  width: 100px;
  height: 16px;
  border-radius: 40px;
  border: 2px solid var(--base-400);
}

.app.tablet .view {
  margin: 60px auto;
  width: 868px;
  height: 1164px;
  background-color: var(--sidebar-bg);
  border-radius: 30px;
  border: 2px solid var(--base-300);
}

.app.tablet .view iframe {
  height: 1024px;
  width: 768px;
  margin: 50px auto 0;
  border: 2px solid var(--base-300);
}

.app.tablet .view::before {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  border: 2px solid var(--base-400);
  content: "";
  width: 50px;
  border-radius: 50%;
  height: 50px;
}

.markup {
  display: none;
}

.toolbar > :nth-child(2) {
  margin-left: auto;
}

.copy-the-block {
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 12px;
  flex-shrink: 0;
  border-radius: 20px;
  margin-right: 14px;
  display: flex;
  font-weight: 600;
  align-items: center;
  color: #fff;
  height: 32px;
  overflow: hidden;
  position: relative;
  justify-content: center;
  width: 130px;
  text-align: center;
  font-size: 13px;
  transition: 0.3s;
}

.copy-to-clipboard {
  white-space: nowrap;
  width: auto;
}

.copy-the-block > svg {
  width: 16px;
  flex-shrink: 0;
  margin-right: 6px;
}

.copy-to-clipboard > svg {
  width: 13px;
}

.knyttneve {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff;
  border-radius: 20px;
  height: 32px;
  width: 210px;
  font-size: 13px;
  text-align: center;
  overflow: hidden;
  font-weight: 500;
}

.knyttneve span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.id {
  transform: translateY(-100%);
}

.knyttneve:hover .id {
  transform: none;
}

.follow svg {
  width: 14px;
  margin-right: 8px;
  height: 14px;
}

.knyttneve:hover .follow {
  transform: translateY(100%);
}

.copy-textarea {
  position: absolute;
  right: -9999990px;
  top: -999999999px;
  opacity: 0;
}

.codes {
  float: right;
  display: none;
  width: 100%;
  transition: 0.3s;
}

.dark-mode .view.show-code,
.dark-mode .codes pre {
  background-color: #1a202c !important;
}

.view.show-code,
.codes pre {
  background-color: #fff !important;
}

.codes pre {
  padding: 20px !important;
  font-size: 13.6px;
  line-height: 1.8;
}

.react-syntax-highlighter-line-number {
  opacity: 0.2;
  pointer-events: none;
  user-select: none;
  font-size: 12px;
}

pre,
code {
  font-family: "Space Mono", monospace;
  font-variant-ligatures: no-contextual;
}

.view.show-code iframe {
  display: none;
}

.view.show-code .codes {
  display: block;
}

.github {
  padding: 8px 12px;
  border-radius: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: var(--solid-900);
  color: #fff;
  display: inline-flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.github svg {
  width: 24px;
  flex-shrink: 0;
  margin-right: 6px;
}

.dark-mode .github {
  color: #1a202c;
  background-color: #fff;
}

.clipboard-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
}

.clipboard-tooltip {
  color: #68717d;
  font-size: 13px;
  background-color: #fff;
  padding: 2px 5px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  position: absolute;
  right: 100%;
  opacity: 0;
  transition: opacity 0.4s, margin-right 0.4s;
}

.clipboard-tooltip.is-copied {
  margin-right: 6px;
  opacity: 1;
}

@media (max-width: 1060px) {
  .device {
    display: none;
  }

  .switcher {
    margin-right: 0;
  }
}

.keyboard-nav {
  position: fixed;
  right: 158px;
  bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyboard-nav-row {
  display: flex;
}

.keyboard-button {
  width: 20px;
  height: 20px;
  opacity: 1;
  border-radius: 4px;
  flex-shrink: 0;
  margin: 1px;
  opacity: 0.5;
  background-color: var(--base-300);
  color: var(--solid-900);
  padding: 3px;
  position: relative;
}

.keyboard-nav:hover .keyboard-button:not(:hover) {
  opacity: 0.2;
}

.keyboard-button:hover {
  opacity: 1;
}

.keyboard-button:before {
  content: attr(data-info);
  position: absolute;
  color: var(--solid-900);
  pointer-events: none;
  font-size: 8px;
  text-transform: uppercase;
  transition: opacity 0.3s;
  text-align: center;
  white-space: nowrap;
  padding: 1px 4px;
  background-color: var(--base-200);
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.keyboard-button.is-active {
  animation-name: keyboard;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes keyboard {
  0% {
    box-shadow: 0;
  }
  50% {
    box-shadow: 0 0 0 5px var(--main-500);
    z-index: 1;
  }
}

.keyboard-button:hover:before {
  opacity: 1;
  z-index: 1;
}

.k-up:before {
  left: 50%;
  transform: translateX(-50%);
  top: 24px;
}

.k-down:before {
  left: 50%;
  transform: translateX(-50%);
  top: -18px;
}

.k-left:before {
  right: -70px;
  top: 4px;
}

.k-right:before {
  left: -76px;
  top: 4px;
}

.keyboard-button:hover:before {
  opacity: 1;
}

@media (max-width: 960px) {
  .copy-to-clipboard {
    display: none;
  }

  .keyboard-nav {
    display: none;
  }
}

@media (max-width: 760px) {
  .copy-the-block {
    display: none;
  }

  .opener {
    width: 40px;
    height: 100%;
    overflow: hidden;
    color: transparent;
    white-space: nowrap;
  }

  .opener::before {
    width: 24px;
    height: 24px;
  }

  .mode {
    margin-left: auto;
  }

  .sidebar {
    z-index: 2;
  }

  .switcher {
    position: fixed;
    bottom: 24px;
    z-index: 1;
    background-color: #fff;
    border: 1px solid var(--base-200);
    right: 150px;
  }

  .dark-mode .switcher {
    background-color: #1a202c;
  }

  .app.has-sidebar .main {
    padding-left: 0;
  }
}

@media (max-width: 380px) {
  .github {
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    color: transparent;
    white-space: nowrap;
  }

  .github svg {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
  }

  .dark-mode .github {
    color: transparent;
  }

  .dark-mode .github svg {
    color: #1a202c;
  }

  .switcher {
    right: 70px;
  }
}
